import { createSlice } from '@reduxjs/toolkit';
import objectsAreEqual from '../../utils/objects-are-equal';
import arraysAreEqual from '../../utils/arrays-are-equal';

export const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState: {
    language: 'de',
    darkmode: false,
    sectionnav: {},
    favoriteorders: [],
    homecards: [
      {
        name: 'welcome',
        visible: false,
      },
      {
        name: 'favorites',
        visible: false,
      },
      {
        name: 'pwa',
        visible: false,
      },
    ],
    selections: [],
    initial: true,
  },
  reducers: {
    updateAppSettings: (state, action) => {
      if (action.payload === null) {
        return state;
      }
      if (
        action.payload.language !== undefined &&
        state.language !== action.payload.language
      ) {
        state.language = action.payload.language;
        state.initial = false;
      }
      if (
        action.payload.darkmode !== undefined &&
        state.darkmode !== action.payload.darkmode
      ) {
        state.darkmode = action.payload.darkmode;
        state.initial = false;
      }
      if (
        action.payload.sectionnav !== undefined &&
        !objectsAreEqual(state.sectionnav, action.payload.sectionnav)
      ) {
        state.sectionnav = action.payload.sectionnav;
        state.initial = false;
      }
      if (
        action.payload.favoriteorders !== undefined &&
        !arraysAreEqual(state.favoriteorders, action.payload.favoriteorders)
      ) {
        state.favoriteorders = action.payload.favoriteorders;
        state.initial = false;
      }
      if (
        action.payload.homecards !== undefined &&
        !arraysAreEqual(state.homecards, action.payload.homecards)
      ) {
        state.homecards = action.payload.homecards;
        state.initial = false;
      }
      if (
        action.payload.selections !== undefined &&
        !arraysAreEqual(state.selections, action.payload.selections)
      ) {
        state.selections = action.payload.selections;
        state.initial = false;
      }
    },
    updateSectionNavSettings: (state, action) => {
      const { sectionnav } = state;
      sectionnav[action.payload.navId] = action.payload.isExpanded;
      state.sectionnav = sectionnav;

      state.initial = false;
    },
    setSelection: (state, action) => {
      const { selections } = state;
      const selectionIndex = selections.findIndex(
        (value) =>
          value.url === action.payload.url && value.type === action.payload.type
      );
      if (selectionIndex < 0) {
        selections.push(action.payload);
      } else {
        selections[selectionIndex] = action.payload;
      }

      state.selections = selections;
      state.initial = false;
    },
  },
});

export const { updateAppSettings, updateSectionNavSettings, setSelection } =
  appSettingsSlice.actions;

export default appSettingsSlice.reducer;
